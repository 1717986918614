export const CasinoRandomGameTranslations = {
  en: {
    randomGame: {
      playRandomGame: 'Play a random game',
      randomGameLoading: 'Selecting ...',
      playNowRandomGame: 'Play Now'
    }
  },
  fr: {
    randomGame: {
      playRandomGame: 'Jouer à un jeu aléatoire',
      randomGameLoading: 'Sélectinner ...',
      playNowRandomGame: 'Jouer maintenant'
    }
  },
  ro: {
    randomGame: {
      playRandomGame: 'Joaca-te un joc aleatoriu',
      randomGameLoading: 'Se selecteaza ...',
      playNowRandomGame: 'Joaca-te Acum'
    }
  },
  tr: {
    randomGame: {
      playRandomGame: 'Rastgele Bir Oyun Oyna',
      randomGameLoading: 'Oyun Seçiliyor',
      playNowRandomGame: 'Şimdi Oyna'
    }
  },
  es: {
    randomGame: {
      playRandomGame: 'Juega un juego aleatorio',
      randomGameLoading: 'Lütfen bekleyin ...',
      playNowRandomGame: 'Şimdi Oyna'
    }
  },
  pt: {
    randomGame: {
      playRandomGame: 'Jogue um jogo aleatório',
      randomGameLoading: 'Selecionando ...',
      playNowRandomGame: 'Jogue agora'
    }
  },
};
